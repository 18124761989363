<template>
  <div>
    <div class="topButton">
      <CRow>
        <CCol sm="4">
          <div class="titleFont floatLeft">專案代碼列表</div>
        </CCol>
        <CCol sm="8">
          <CButton
            variant="outline"
            class=" floatRight rbutton"
            color="primary"
          >
            新增專案代碼
          </CButton>

          <v-select
            class="floatRight selectCompany2 mtop20"
            v-model="selected"
            :options="selectOptions"
            :filterable="true"
          >
          </v-select>
        </CCol>
      </CRow>
    </div>
    <div class="clearBoth"></div>
    <CCard>
      <CCardBody>
        <div class="positionR">
          <div class="tableTitle">
            <div role="group" class="filterSelect form-group">
              <!-- <label for="uid-ksovz61x97" class=""> 月份 </label> -->
              <select
                id="yearMonth"
                class="form-control billMonth"
                v-model="selectDaily"
              >
                <option disabled value="0">帳單月份:</option>
                <option
                  :data-key="key"
                  v-for="(value, key) in SelectSixMonth"
                  :key="key"
                >
                  {{ value.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <CDataTable
          :items="exchangeRate"
          :fields="fields"
          :sorter="{ external: true }"
          @update:sorter-value="handlerSorterValueChange"
          hover
          :noItemsView="{ noItems: '目前無單據資料' }"
        >
          <template #edit="{item, index}">
            <td class="py-2">
              <CButton
                color="success"
                variant="outline"
                square
                size="sm"
                class="ml-1"
                @click="view(item, index)"
              >
                檢視
              </CButton>
              <CButton
                color="dark"
                variant="outline"
                square
                size="sm"
                class="ml-1"
                @click="edit(item, index)"
              >
                編輯
              </CButton>
              <CButton
                color="danger"
                variant="outline"
                square
                size="sm"
                class="ml-1"
              >
                刪除
              </CButton>
            </td>
          </template>
        </CDataTable>

        <div v-if="page != undefined">
          <CPagination
            :activePage.sync="page.current_page"
            :pages="page.last_page"
            align="end"
            @click.native="get(page.current_page)"
            v-if="page != ''"
          />
        </div>
      </CCardBody>
    </CCard>

    <!-- 共用錯誤彈窗 -->
    <Modal></Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Modal from '../Modal.vue'
export default {
  name: 'Clients',
  //   props: ['itemTable', 'fieldsTable'],
  data() {
    return {
      details: [],
      collapseDuration: 0,
      userAuth: sessionStorage.getItem('userAuth'),
      search: '',
      orderBy: '',
      sortedBy: '',
      selectDaily: 0,
      selected: '',
      exchangeRate: [
        {
          id: 'ba054613',

          name: 'ECV',
          parent: 'Dynacloud',
        },
        {
          id: 'ba0947532',

          name: 'CompanyA',
          parent: 'ECV',
        },
        {
          id: 'ba0495132',

          name: 'CompanyB',
          parent: 'ECV',
        },
      ],
      page: { current_page: 1, last_page: 1 },
      SelectSixMonth: [
        { id: 1, name: '202205' },
        { id: 1, name: '202204' },
        { id: 1, name: '202203' },
      ],
    }
  },
  components: {
    Modal,
  },
  computed: {
    ...mapGetters([
      'get_companies',
      'get_companiesPage',
      'get_inStock',
      'get_companiesAll',
    ]),
    fields() {
      return [
        { key: 'id', label: '專案代碼' },

        { key: 'name', label: '公司名稱' },
        { key: 'parent', label: '上層公司' },

        {
          key: 'edit',
          label: '',
          _style: 'width:20%',
          sorter: false,
          filter: false,
        },
      ]
    },
    // 公司下拉
    selectOptions() {
      if (this.company) {
        return this.company.map((g) => ({
          label: g.name,
          id: g.id,
        }))
      }
    },
  },
  watch: {
    selectOptions() {
      $('.vs__search').attr('value', '請選擇')
    },
  },
  methods: {
    handlerSorterValueChange(o) {
      this.orderBy = o.column
      this.sortedBy = o.asc == true ? 'ASC' : 'DESC'
      let data = {
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        page: 1,
      }
      this.$store.dispatch('actionCompanies', data)
    },
    createCompany() {
      this.$router.push('/company/create')
    },
    view(item) {
      this.$router.push('/projectCode/view/' + item.id)
    },
    edit(item) {
      this.$router.push('/projectCode/edit/' + item.id)
    },
    users(item) {
      this.$router.push('/provider/client_user/' + item.id)
    },
    get(page) {
      let data = {
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        page: page,
      }
      this.$store.dispatch('actionCompanies', data)
    },
    Contract(item) {
      this.$router.push('/provider/company/contract/' + item.id)
    },
    getCompany() {
      let data = {
        companyName: this.search,
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        page: 1,
      }
      this.$store.dispatch('actionCompanies', data)
    },
  },
  created() {
    // this.orderBy = 'created_at'
    // this.sortedBy = 'DESC'
    // let data = {
    //   orderBy: this.orderBy,
    //   sortedBy: this.sortedBy,
    // }
    // this.$store.dispatch('actionCompanies', data)
    // let dataCompany = {
    //   orderBy: '',
    //   sortedBy: 'DESC',
    // }
    // this.$store.dispatch('actionCompaniesAll', dataCompany)
  },
}
</script>
